import React from "react"

import config from "../data/config"
import Body from '../components/layout/default/main'

export default () => (
    <Body>
        <div>
            <div>
                <h2>Problems with the site?</h2>
                <p>Be sure to contact the <a href={`mailto:${config.contactEmail}`}>webmaster</a> with a subject line of: "help with kadebom.com" at anytime. They will get back with you as quickly as possible. Any feedback or improvements are more than welcome!</p>
                <h2>Business Inquiries</h2>
                <p>kadebom.com is always open to business inquires of any sort. With a specialization in "unspecializable" tech, anything from Machine Learning to Mobile Apps is within reach! Feel free to contact <a href={`mailto:${config.contactEmail}`}>kade</a> at anytime.</p>
            </div>
            <div>
                <h2>Social Media</h2>
                <ul>
                    <li><a href={config.twitterUrl}>Twitter</a></li>
                    <li>Medium</li>
                    <li><a href={config.linkedinUrl}>Linkedin</a></li>
                    <li>Facebook</li>
                </ul>
            </div>
        </div>
    </Body>
)
